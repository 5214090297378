import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import { Helmet } from 'react-helmet'
import Button from 'components/button/button'
import Layout from 'components/layout'
import Content from 'components/new/content'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import Spacer from 'components/new/spacer'
import HeaderWithLink from 'components/new/header-with-link'
import PromotionsSlider from 'components/new/promotions-slider'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const PartsPage = props => {
  const {
    data: {
      allHutsonPromotion,
      allSanityLocation: { totalCount: locationCount },
      heroImage,
      hutsonPromiseLogo,
      lawnGardenPartsImage,
      agPartsImage,
      aiProductsImage,
      partsOnsiteImage,
      partsDropBoxImage,
      bulkOilImage,
    },
  } = props
  const promos = allHutsonPromotion
    ? allHutsonPromotion.nodes.map(node => {
        return {
          ...node,
        }
      })
    : []
  return (
    <Layout>
      <Helmet>
        <title>Parts | Hutson Inc</title>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Parts',
                'item': 'https://www.hutsoninc.com/parts/',
              },
            ],
          })}
        </script>
      </Helmet>

      <Hero image={heroImage.childImageSharp.gatsbyImageData} title='Parts' overlayOpacity={0.3} />

      <Content kind='full'>
        <H2>Parts at Hutson</H2>
        <P>
          Hutson is proud to be your source for genuine John Deere parts. In addition to John Deere
          parts, we carry a large stock of parts for the many other brands we offer. At Hutson, we
          consider customer satisfaction to be a priority. We feel it is important to have the parts
          you need at a price you can afford. We carry a tiered parts portfolio so you can choose
          between our good, better and best parts options. With our{' '}
          <Link to='/locations/'>{locationCount} locations</Link>, we have one of the largest parts
          inventories around. If we do not have the part you need we will have it the next day. Our
          highly trained staff will be glad to help you get the parts you need.
        </P>
        <P>
          We offer monthly promotions to help you get the most for your dollar. Hutson also offers
          No Payments/No Interest financing with your John Deere Multi-Use Account to help with cash
          flow. If you are not already a Multi-Use Account customer, ask us how to get signed up.
        </P>
        <Spacer />
        <H2>Buy Parts Online</H2>
        <Spacer size='s' />
        <Grid>
          <Column>
            <GatsbyImage image={lawnGardenPartsImage.childImageSharp.gatsbyImageData} alt='' />
            <Spacer size='m' />
            <H3>Shop.Deere.com</H3>
            <P>
              The parts you need, delivered right to your doorstep. Browse parts diagrams, check
              equipment parts compatibility, check out with your MyJohnDeere account and more!
            </P>
            <Spacer size='s' />
            <ButtonContainer>
              <Button
                as={OutboundLink}
                href='https://shop.deere.com/us/?dealer-id=034321&CID=LNK_DLR_enUS_OS_HutsonWebsite'
                ghost
                color='green'
              >
                Visit Shop.Deere.com
              </Button>
            </ButtonContainer>
          </Column>
          <Column>
            <GatsbyImage image={agPartsImage.childImageSharp.gatsbyImageData} alt='' />
            <Spacer size='m' />
            <H3>Hutson Customer Portal</H3>
            <P>
              View order history, keep Parts OnSite cabinet stocked, manage your fleet and check our
              our parts inventory without driving to the dealership. Get all that plus more with the
              Hutson Customer Portal!
            </P>
            <Spacer size='s' />
            <ButtonContainer>
              <Button as={Link} to='/hutson-customer-portal/' ghost color='green'>
                Learn more about the Hutson Customer Portal
              </Button>
            </ButtonContainer>
          </Column>
          <Column>
            <GatsbyImage image={aiProductsImage.childImageSharp.gatsbyImageData} alt='' />
            <Spacer size='m' />
            <H3>All Parts Store</H3>
            <P>
              We've partnered with A&amp;I Products to offer a wide selection of aftermarket parts
              for all makes and models of ag equipment. A&amp;I Products supplies high-quality parts
              at a reasonable price.
            </P>
            <Spacer size='s' />
            <ButtonContainer>
              <Button
                as={OutboundLink}
                href='https://www.allpartsstore.com/index.htm?CustomerNumber=KY4456&StoreAccount='
                ghost
                color='green'
              >
                Shop All Parts Store
              </Button>
            </ButtonContainer>
          </Column>
        </Grid>
        {promos.length > 0 ? (
          <>
            <Spacer />
            <HeaderWithLink to='/promotions/parts/' linkText='View all promotions'>
              Parts Promotions
            </HeaderWithLink>
            <Spacer size='m' />
            <PromotionsSlider kind='light' items={promos} />
          </>
        ) : null}
      </Content>
      <LightSection>
        <Content kind='full'>
          <Grid>
            <Column>
              <GatsbyImage image={partsOnsiteImage.childImageSharp.gatsbyImageData} alt='' />
              <ColumnContent>
                <H3>Parts OnSite</H3>
                <P>
                  Spend more time in the field and less time at the parts counter with Parts OnSite.
                  We'll stock common parts in a cabinet at your shop so you can grab what you need
                  and get back to work!
                </P>
                <Spacer size='s' />
                <ButtonContainer>
                  <Button as={Link} to='/parts/parts-onsite/' ghost color='green'>
                    Learn more about Parts OnSite
                  </Button>
                </ButtonContainer>
              </ColumnContent>
            </Column>
            <Column>
              <GatsbyImage image={partsDropBoxImage.childImageSharp.gatsbyImageData} alt='' />
              <ColumnContent>
                <H3>Parts Drop Box</H3>
                <P>
                  Can't make it to our stores to pick up a part? We've got Parts Drop Boxes
                  scattered throughout our area to help you get the parts you need, when you need
                  them.
                </P>
                <Spacer size='s' />
                <ButtonContainer>
                  <Button as={Link} to='/parts/parts-drop-box/' ghost color='green'>
                    Learn more about Parts Drop Box
                  </Button>
                </ButtonContainer>
              </ColumnContent>
            </Column>
            <Column>
              <GatsbyImage image={bulkOilImage.childImageSharp.gatsbyImageData} alt='' />
              <ColumnContent>
                <H3>Bulk Oil</H3>
                <P>
                  Go through a ton of oil each season? We offer a bulk oil delivery service to fill
                  up 250 and 500 gallon oil tanks at your farm!
                </P>
                <Spacer size='s' />
                <ButtonContainer>
                  <Button as={Link} to='/parts/bulk-oil/' ghost color='green'>
                    Learn more about Bulk Oil
                  </Button>
                </ButtonContainer>
              </ColumnContent>
            </Column>
          </Grid>
        </Content>
      </LightSection>
      <Content kind='full'>
        <PromiseSection>
          <PromiseCopy>
            <H2>The Hutson Parts Promise</H2>
            <P>
              The Hutson Promise is a guarantee that we will match any competitors retail price on
              genuine John Deere Ag, Turf, and Compact Construction Equipment parts.
            </P>
          </PromiseCopy>
          <PromiseLogo
            image={hutsonPromiseLogo.childImageSharp.gatsbyImageData}
            alt='Hutson Promise Logo'
          />
        </PromiseSection>
      </Content>
    </Layout>
  )
}

const LightSection = styled.div`
  background-color: ${props => props.theme.color.n20};
`

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

const ButtonContainer = styled.div`
  a {
    display: inline-block;
    text-decoration: none;
  }
`

const Grid = styled.div`
  @media (min-width: 900px) {
    ${clearfix}
  }
`

const Column = styled.div`
  background-color: #fff;

  @media (max-width: 899px) {
    :not(:last-child) {
      margin-bottom: ${props => props.theme.size.l};
    }
  }

  @media (min-width: 900px) {
    ${column('1/3')}
  }
`

const ColumnContent = styled.div`
  padding: ${props => props.theme.size.m};
`

const PromiseSection = styled.div`
  @media (min-width: 800px) {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`

const PromiseCopy = styled.div`
  margin: 0 0 36px;

  @media (min-width: 800px) {
    margin: 0 36px 0 0;
  }
`

const PromiseLogo = styled(GatsbyImage)`
  max-width: 250px;
  width: 100%;
`

export const pageQuery = graphql`
  {
    allSanityLocation(
      filter: { status: { ne: "permanently-closed" }, retailLocation: { eq: true } }
    ) {
      totalCount
    }
    heroImage: file(relativePath: { eq: "parts/parts-hero.jpg" }) {
      ...FullWidthImage
    }
    agPartsImage: file(relativePath: { eq: "parts/ag-parts.jpg" }) {
      ...SharpImage480
    }
    lawnGardenPartsImage: file(relativePath: { eq: "parts/lawn-garden-parts.jpg" }) {
      ...SharpImage480
    }
    aiProductsImage: file(relativePath: { eq: "parts/ai-products.jpg" }) {
      ...SharpImage480
    }
    partsOnsiteImage: file(relativePath: { eq: "parts/parts-onsite.jpg" }) {
      ...SharpImage480
    }
    partsDropBoxImage: file(relativePath: { eq: "parts/parts-drop-box.jpg" }) {
      ...SharpImage480
    }
    bulkOilImage: file(relativePath: { eq: "parts/bulk-oil.jpg" }) {
      ...SharpImage480
    }
    allHutsonPromotion(
      filter: { tags: { in: ["parts"] } }
      sort: { fields: [endDate], order: DESC }
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    hutsonPromiseLogo: file(relativePath: { eq: "hutson-promise.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 250)
      }
    }
  }
`

export default PartsPage
